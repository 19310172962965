.text-left {
  text-align: left;
}

.first-mobile .icon {
  display: none;
}
.dropdown {
  display: flex;
  width: 100%;
  justify-content: center;
}
.dropdown .icon {
  flex: 0.6;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  transition: all ease 300ms;
}
.dropdown span {
  flex: 1;
}
.last {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.last span {
  margin-left: 30%;
}

.status-indicator {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f5c54b;
  transition: all ease 300ms;
}
.status-indicator.finished {
  background: #6bb56a;
}
.selected .status-indicator {
  border: 1px solid #fff;
}

.tooltip {
  background: #1e1e1e;
  position: absolute;
  left: 1;
  top: 1;
  color: #fff;
  padding: 0.25rem 0.5rem;
  transition: all ease 200ms;
}

@media screen and (max-width: 480px) {
  .desktop {
    display: none;
  }
  .row {
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem 0;
  }
  .first-mobile {
    display: flex;
    width: 100%;
  }
  .first-mobile .icon {
    display: flex;
    margin-left: 0.25rem;
  }
  .first-mobile span {
    flex: 1;
  }
  .last {
    justify-content: space-around;
  }
  .last span {
    margin-left: 0.5rem;
  }
}
