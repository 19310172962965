.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.actions {
  display: flex;
  justify-content: space-evenly;
  padding-block: 1rem;
}

.actions button {
  cursor: pointer;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  box-shadow: 0 4px 4px #00000040;
  border-radius: 8px;
  gap: 0.5rem;
}
.cancel {
  background-color: #f54b4f;
}
.finish {
  background-color: #6bb56a;
}
