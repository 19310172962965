.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  font-weight: 700;
  color: var(--error);
  font-size: 1.5rem;
}

.success {
  color: var(--success);
}

.ok-btn {
  width: 100px;
}
