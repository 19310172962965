.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-weight: 700;
}

.actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
