.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 42.5%;
  gap: 2rem;
  padding-bottom: 1rem;
}

.modal-actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 480px) {
  .content {
    width: 95%;
  }
}
