@page {
  size: A4;
  margin: 2cm 0.5cm;
  margin-left: 0;
  margin-right: 0;
}

.container {
  width: 100%;
  min-height: 100vh;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #6bb56a;
  font-family: Montserrat, Arial;
}
.header {
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.header img {
  width: 250px;
  margin-right: -1px;
}
.backBtn {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border: none;
  background: #fff;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 2.25rem;
  padding-top: 2.2rem;
  padding-right: 0.5rem;
  overflow-x: hidden;
  border-bottom-right-radius: 45px;
  border-top-right-radius: 45px;
  min-height: 100vh;
}

.content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.content h2 {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: #6bb56a;
}
h1,
h2,
h3 {
  padding: 0.5rem 0 0.5rem 0.5rem;
}
.downloadBtn {
  all: unset;
  padding: 1rem;
  background: #6bb56a;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.downloadBtn:hover {
  background: #619b60;
}
@media screen and (max-width: 480px) {
  .container {
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .content {
    padding-left: 0rem;
    padding-right: 0.5rem;
    border-bottom-right-radius: 18px;
    border-top-right-radius: 18px;
  }
  .header img {
    width: 185px;
    margin-bottom: -1px;
  }
}
