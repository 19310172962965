.input-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &.error .input-wrapper {
    border: 2px solid var(--error, #e53835);
    background: #fff5f5;
  }
}

.input-container label {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.input-container .input-hint {
  color: #5e5f60;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-wrapper {
  display: flex;
  min-height: 48px;
  padding: 8px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #eef1f6;
}
.input-wrapper input {
  all: unset;
  cursor: pointer;
  width: 100%;

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;

  &::placeholder {
    color: #89939e;
  }
}

.input-wrapper:focus-within {
  border: 1px solid var(--l_grey);
}
.input-wrapper:has(:disabled) {
  background: #e7e7e7;
  font-weight: 500;
  color: #89939e;
}
.input-wrapper .input-icon {
  font-size: 24px;
}
