.default-root {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.default-label {
  color: var(--black, black);
  font-weight: 500;

  &.sm {
    font-size: 0.875rem;
  }
  &.md {
    font-size: 1rem;
  }
  &.lg {
    font-size: 1.125rem;
  }
}

.input-box {
  display: flex;
  align-items: center;
  position: relative;
}

.input-box svg {
  position: absolute;
  right: 8px;
  height: 24px;
  width: auto;
}

.default-input {
  padding: 0.5rem 1rem;
  flex: 1;
  border: none;

  border-radius: 8px;
  background: #eef1f6;

  color: var(--black, #000);
  font-size: 1.125rem;
  font-weight: 500;
  border: 1px solid transparent;
}

.default-input.danger {
  border: 1px solid var(--error_text, #e53835);
  outline: 1px solid var(--error_text, #e53835);
  background: #fff5f5;
}

.default-input.success {
  border: 1px solid var(--primary, #2e7d31);
  outline: 1px solid var(--primary, #2e7d31);
  background: #f7fff5;
}

.default-input:focus {
  border: 1px solid var(--primary) !important;
  outline: 1px solid var(--primary);
  background: #eef1f6;
}

.default-hint {
  color: var(--label-default, #5e5f60);
  font-size: 0.875rem;
  font-weight: 500;

  color: #5e5f60;
}

.default-hint.danger {
  color: var(--error_text, #e53835);
  font-weight: 500;
}

.default-hint.success {
  color: var(--primary, #2e7d31);
  font-weight: 500;
}
