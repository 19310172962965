.container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container section {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  height: 88vh;
  overflow: hidden;
  align-items: center;
}
.actions {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2.5rem;
}
.info {
  display: flex;
  gap: 1rem;
  height: 100%;
}
.info .button {
  display: flex;
  font-size: 1.25rem;
}

.order-service-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  width: 96%;
  background: var(--white);
}
.order-service-list-header {
  position: sticky;
  top: 0;
  z-index: 1;
  /* background-color: #f0f0f0; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-service-list .header {
  width: 95%;

  display: grid;
  align-items: center;
  background-color: #e9e9e9;
  color: #1e1e1e;
  font-weight: bold;

  grid-template-columns: 0.5fr repeat(5, 1fr);
  justify-items: center;
  padding: 0.75rem;
  border-radius: 8px;
}
.order-service-list-content {
  width: 95%;

  margin-top: 1rem;

  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
.order-service-list-content-wrapper {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}
.empty {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  color: #1e1e1e;
  font-size: 2rem;
}
.mobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .container section {
    height: 85vh;
  }
  .mobile-header {
    display: none;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    width: 90%;
  }
  .order-service-list .header {
    grid-template-columns: repeat(3, 1fr);
  }
  .order-service-list-content {
    overflow-y: scroll;
  }
  .actions {
    padding: 1rem 1rem;
  }
  .order-service-list-content-wrapper {
    max-height: 60vh;
  }
  .info {
    gap: 0;
    width: 45%;
  }
  .info .button {
    /* background-color: red; */
    font-size: 0.9rem;
    flex: 1;
    width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .actions {
    padding: 1rem 1rem;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    width: 90%;
  }
}
