.filter-container {
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.date-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filters {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.search-input {
  border: 1px solid #a5a5a5;
  background: transparent;
  padding: 1rem 2rem;

  border-radius: 8px;
}

.date-container {
  display: flex;
  gap: 0.5rem;
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.loader {
  margin-top: 1rem;
  font-size: 14px;
  color: #555;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .filter-container {
    padding: 0.5rem;
    width: 100%;
  }

  .filters {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .date-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .search-input {
    max-width: 200px;
  }
}
