.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  height: 400px;
  background: #fff;
  border-radius: 20px;
  padding-bottom: 1rem;
  overflow: hidden;
}
.header {
  display: flex;
  color: #6bb56a;
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}
.header h2 {
  text-align: center;
  flex: 1;
}
.header button {
  all: unset;
  cursor: pointer;
}
.body {
  flex: 1;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.body p {
  color: #133240;
  font-size: 1.2rem;
}
.body img {
  height: 200px;
  object-fit: contain;
}
.body button {
  all: unset;
  background: #6bb56a;
  color: #fff;
  border-radius: 20px;
  padding: 1.5rem;
  font-size: 1.2rem;
  font-weight: bolder;
  width: 65%;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .content {
    width: 90%;
  }
  .body {
    width: 75%;
    text-align: center;
  }
  .body img {
    height: 150px;
  }
}
